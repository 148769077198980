<template>
  <div>
    <el-dialog
        title="申报支出详情"
        width="65%"
        :visible.sync="pageState"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-row>
        <legend>申报详情展示</legend>
        <el-table
            :data="declareArray"
            border
            v-loading="loading"
            size="medium"
            element-loading-text="拼命加载中"
            max-height="630"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :default-sort = "{prop: 'createDate', order: 'descending'}"
            style="width: 99%">
          <el-table-column prop="declareName" label="申报人" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
          <el-table-column prop="declarationDate" label="申报日期" align="center" width="120" sortable fixed></el-table-column>
          <el-table-column prop="spendMethodName" width="120" align="center" label="收款方式"></el-table-column>
          <el-table-column prop="spendAmount" width="125" align="center" label="收款总金额"></el-table-column>
          <el-table-column width="100" align="center" label="申报状态">
            <template slot-scope="scope">
            <span>
              {{scope.row.state === 0 ? "新建未提交" :scope.row.state === 1 ? "提交待审核" :scope.row.state === 2 ? "已审核待支付" :scope.row.state === 3 ? "已支付" :scope.row.state === 4 ? "已退回" : "申报已完成"}}
            </span>
            </template>
          </el-table-column>
          <el-table-column prop="reviewerName" width="120" align="center" label="审核人"></el-table-column>
          <el-table-column prop="clearingPersonName" width="120" align="center" label="结算人"></el-table-column>
          <el-table-column prop="spendTotal" width="120" align="center" label="支出总数" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" width="220" align="center" label="申报描述" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="remark" width="220" align="center" label="退回批注" :show-overflow-tooltip="true"></el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <br>
        <legend>此条申报所添加的支出</legend>
        <el-table
            :data="SpendArray"
            border
            v-loading="loading"
            size="medium"
            element-loading-text="拼命加载中"
            max-height="630"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :default-sort = "{prop: 'createDate', order: 'descending'}"
            style="width: 99%">
          <el-table-column prop="declarationDate" label="支出日期" align="center" width="170" sortable fixed></el-table-column>
          <el-table-column prop="spendProjectsName" width="150" align="center" label="支出项目"></el-table-column>
          <el-table-column prop="shopName" width="125" align="center" label="店铺"></el-table-column>
          <el-table-column prop="spendAmount" width="125" align="center" label="支出金额"></el-table-column>
          <el-table-column width="125" align="center" label="状态">
            <template slot-scope="scope">
            <span>
              {{scope.row.settlement === 0 ? "预支" : "结算"}}
            </span>
            </template>
          </el-table-column>
          <el-table-column prop="content" width="220" align="center" label="支出描述" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width="250" align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="primary" size="mini" @click="open(scope.row)">支出图片</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row v-if="isOperation">
        <br>
        <legend>申报退回批注</legend>
        <el-input
            placeholder="请输入内容"
            v-model="declareReturn"
            clearable>
        </el-input>
      </el-row>
      <span v-if="isOperation" slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="Approved()" size="medium">审核通过</el-button>
        <el-button type="danger" @click="returnDeclare()" size="medium">退回审核</el-button>
      </span>
      <el-form v-if="isPayment" style="margin-top: 20px" :rules="payRules" :model="paymentForm" ref="paymentForm">
        <el-form-item label="请选择申报支付方式：" prop="declareMethod">
          <el-select
              v-model="paymentForm.declareMethod"
              placeholder="请选择申报支付方式"
              clearable
          >
            <el-option
                :label="item.name"
                v-for="item in spendMethodArray"
                :value="item.value"
                :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="若退回申报，请填写原因：" prop="remark">
          <el-input
              type="textarea"
              v-model="paymentForm.remark"
              style="width: 80%"
              :rows="5"
              resize="none"
              placeholder="请填写退回原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <span v-if="isPayment" slot="footer" class="dialog-footer">
        <el-button type="success" @click="finishPayment()" size="medium">完成支付</el-button>
        <el-button type="danger" @click="returnPayDeclare()" size="medium">退回申报</el-button>
      </span>
    </el-dialog>
    <spend-image :visible.sync="declareImageState" v-if="declareImageState" :spend-image="declareUrl" :id="id" @close="querySpendList"></spend-image>
  </div>
</template>

<script>
import spendImage from "@/pages/spend/spend/spend-image";
export default {
  name: "declare-edit",
  components: {
    spendImage
  },
  created() {
    this.pageInit()
  },
  props: {
    declareEditState: {
      type: Boolean,
      default: false,
      required: true,
    },
    isOperation: {
      type: Boolean,
      default: false,
      required: true,
    },
    declare: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    isPayment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      declareUrl:"",
      id: '',
      declareImageState: false,
      loading: true,
      fileList: [],
      declareArray: [],
      SpendArray: [],
      shops: [],
      spendMethodArray: [],
      dialogImageUrl: "",
      declareReturn: "",
      form: {
        spendAmount: "",
        spendProjectsId: "",
        shopId: "",
        content: "",
        spendImage: "",
        remark: "",
        declareId: this.declare.id,
        declarationDate: new Date(),
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      pageState: this.declareEditState,
      declareForm: {
        ...this.declare
      },
      paymentForm: {
        declareMethod: '',
        remark: '',
      },
      rules: {
        spendPeople: [{required: true, message: '请输入申报人', trigger: 'blur'}],
        spendMethod: [{required: true, message: '请选择收款方式', trigger: 'blur'}],
        spendAmount: [{required: true, message: '请输入支出金额', trigger: 'blur'}],
        spendProjectsId: [{required: true, message: '请选择支出项目', trigger: 'blur'}],
        shopId: [{required: true, message: '请选择店铺', trigger: 'blur'}],
        declarationDate: [{required: true, message: '请选择支出时间', trigger: 'blur'}],
        content: [{required: true, message: '请输入申报内容', trigger: 'blur'}],
      },
      payRules: {
        declareMethod: [{required: true, message: '请选择申报支付方式', trigger: 'blur'}],
      }
    }
  },
  methods: {
    //查询申报
    queryDeclare() {
      if (this.declareArray.length===0) {
        this.declareArray.push(this.declare);
      }
    },
    //查询支出列表 根据申报id
    querySpendList() {
      this.$axios({
        method: "GET",
        url: "/spend/querySpendList",
        params: {
          declareId: this.declare.id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.loading = false
        this.SpendArray = response.data.data.list;
      })
    },
    //查询申报支付方式
    queryAccount() {
      this.$selectUtils.queryAccountIds(0).then(response => {
        this.spendMethodArray = JSON.parse(response.data.data)
      })
    },
    //审核通过
    Approved() {
      this.$alert("确认通过此条申报吗？",
          "审核申报",
          {
            dangerouslyUseHTMLString: true,
            type: "success",
            center: true,
            showCancelButton: true
          }).then(() => {
        this.$axios({
          method: "POST",
          url: "/spendDeclare/updateSpendDeclare",
          params: {
            id: this.declare.id,
            state: 2,
            reviewerId: JSON.parse(localStorage.getItem("emp")).id,
          },
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '申报审核成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.$emit('close')
        })
      }).catch(() => {
      })
    },
    //退回审核
    returnDeclare() {
      if (this.declareReturn.length===0) {
        this.$message({
          showClose: true,
          message: '请输入退回批注',
          type: 'error',
          duration: 1000,
        });
      } else {
        this.$alert("确认退回此条申报吗？",
            "审核申报",
            {
              dangerouslyUseHTMLString: true,
              type: "success",
              center: true,
              showCancelButton: true
            }).then(() => {
          this.$axios({
            method: "POST",
            url: "/spendDeclare/updateSpendDeclare",
            params: {
              id: this.declare.id,
              state: 4,
              remark :this.declareReturn,
              reviewerId: JSON.parse(localStorage.getItem("emp")).id,
            },
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '退回申报成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            this.$emit('close')
          })
        }).catch(() => {
        })
      }
    },
    open(data) {
      this.declareUrl = data.spendImage;
      this.id = data.id;
      this.declareImageState = true
    },
    declareImageClose() {
      this.declareImageState = false
    },
    pageInit() {
      /* 搜索本条申报 */
      this.queryDeclare();
      /* 搜索支出列表 */
      this.querySpendList();
      /* 搜索申报支付方式 */
      this.queryAccount();
    },
    close() {
      this.$emit("close");
    },
    // 完成支付
    finishPayment() {
      this.$refs['paymentForm'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/spendDeclare/payDeclare",
            params: {
              clearingPersonId: JSON.parse(localStorage.getItem("emp")).id,
              spendMethodId: this.paymentForm.declareMethod,
              id: this.declare.id,
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success('支付成功')
              this.$emit('close')
            } else {
              this.$message.error(response.data.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    // 支付时退回申报
    returnPayDeclare() {
      if (this.paymentForm.remark === '') {
        this.$message.error("请填写退回原因")
        return false
      }
      this.$axios({
        method: 'POST',
        url: '/spendDeclare/returnDeclare',
        params: {
          id: this.declare.id,
          remark :this.paymentForm.remark,
          reviewerId: JSON.parse(localStorage.getItem("emp")).id,
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('退回成功')
          this.$emit('close')
        } else {
          this.$message.error(response.data.msg)
        }
      })
    }
  },
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>