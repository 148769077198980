<template>
  <el-dialog
      title="支出图片"
      :visible.sync="show"
      width="30%"
      :show-file-list="false"
      @open="open"
      center>
    <el-row>
      <el-col style="text-align: center">
        <el-image
            style="width: 300px; height: 300px"
            :src="spendPictureUrl"
            :preview-src-list="[spendPictureUrl]">
        </el-image>
      </el-col>
      <el-col style="text-align: center; margin-top: 20px">
        <el-upload
            action="#"
            :http-request="uploadSpendPicture"
            :limit="1"
            ref="spendPicture"
        >
          <el-button size="small" type="primary">点击重新上传</el-button>
        </el-upload>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-picture",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    },
    spendImage: {
      required: true
    },
    id: {
      required: true
    }
  },
  computed: {
    show: {
      set(value) {
        this.$emit('update:visible', value)
      },
      get() {
        return this.visible
      }
    }
  },
  data() {
    return {
      baseUrl: "https://expend-image-1304365928.cos.ap-shanghai.myqcloud.com/",
      spendPictureUrl: "https://expend-image-1304365928.cos.ap-shanghai.myqcloud.com/" + this.spendImage,
    }
  },
  methods: {
    // 修改支出图片
    uploadSpendPicture(param) {
      const data = new FormData()
      data.append('id', this.id)
      data.append('file', param.file)
      this.$axios({
        method: 'POST',
        url: '/spend/editSpendImage',
        data: data
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('上传成功')
          this.spendPictureUrl = this.baseUrl + response.data.data
          this.$refs['spendPicture'].clearFiles()
          this.$emit('close')
        } else {
          this.$message.error(response.data.msg)
          this.$refs['spendPicture'].clearFiles()
        }
      })
    },
    open() {

    },
  },
}
</script>

<style scoped>

</style>