<!--审核列表-->
<template>
  <div>
    <el-row :gutter="20" style="margin-top:15px">
      <legend>审核申报</legend>
      <br>
      <el-table
          :data="SpendDeclareArray"
          border
          v-loading="loading"
          size="medium"
          element-loading-text="拼命加载中"
          max-height="630"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          style="width: 99%">
        <el-table-column prop="declareName" label="申报人" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="declarationDate" label="申报日期" align="center" width="120" sortable fixed></el-table-column>
        <el-table-column prop="spendMethodName" width="120" align="center" label="收款方式"></el-table-column>
        <el-table-column prop="spendAmount" width="125" align="center" label="收款总金额"></el-table-column>
        <el-table-column width="120" align="center" label="申报状态">
          <template slot-scope="scope">
            <span>
              {{scope.row.state === 0 ? "新建未提交" :scope.row.state === 1 ? "提交待审核" :scope.row.state === 2 ? "已审核待结算" :scope.row.state === 3 ? "已结算" :scope.row.state === 4 ? "已退回" : "申报已完成"}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewerName" width="120" align="center" label="审核人"></el-table-column>
        <el-table-column prop="clearingPersonName" width="120" align="center" label="结算人"></el-table-column>
        <el-table-column prop="spendTotal" width="120" align="center" label="支出总个数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" width="220" align="center" label="申报描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="content" width="220" align="center" label="退回批注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="240" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" size="mini" @click="declareEditOpen(scope.row)">审核</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <declareEdit :declare="declare" :isOperation="isOperation" :declareEditState="declareEditState" v-if="declareEditState" @close="declareEditClose"></declareEdit>
  </div>
</template>

<script>
import declareEdit from "@/pages/spend/declare/declare-edit";

export default {
  name: "examine-list",
  created() {
    this.pageInit()
  },
  components: {
    declareEdit,
  },
  data() {
    return {
      total: 0,
      declare: {},
      declareEditState: false,
      isOperation: true,
      limit: 100,
      loading: true,
      SpendDeclareArray: [],
    }
  },
  methods: {
    //查询待审核列表列表
    querySpendDeclareList() {
      this.$axios({
        method: "GET",
        url: "/spendDeclare/spendDeclareList",
        params: {
          state: 1,
          declareId: JSON.parse(localStorage.getItem("emp")).id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.loading = false
        this.SpendDeclareArray = response.data.data.list;
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppList()
    },
    declareEditOpen(val) {
      this.declare = val;
      this.declareEditState = true;
    },
    declareEditClose() {
      this.declareEditState = false
      this.querySpendDeclareList()
    },
    pageInit() {
      /* 搜索申报列表 */
      this.querySpendDeclareList();
    },
  }
}
</script>

<style scoped>

</style>